<template>
  <div :class="$style.root">
    <main :class="$style.main">
      <div :class="$style.page">
        <slot />
      </div>
    </main>

    <DynamicPageComponent />
  </div>
</template>

<script setup>
  const menuOpen = ref(false)
  const clustersCitiesOpen = ref(false)
</script>

<style module>
.root {
  min-height: var(--100svh, 100vh);

  display: flex;
  flex-direction: column;

  background-color: #cbe5f4;
}

.main {
  grid-area: main;
}

.page {
  padding-bottom: calc(var(--unit--vertical) * 6);
  min-height: calc(var(--100dvh) - var(--unit--vertical) * 2 - var(--font-small--line-height));

  display: flex;
  flex-direction: column;
}
</style>
